<template>
  <section class="common_questions_container">
    <div
      v-for="(question, questionIndex) in commonQuestions"
      :key="questionIndex"
      class="question"
      :class="{ activeQuestion: questionOpenIndex === questionIndex }"
    >
      <div class="head" @click="toggleQuestion(questionIndex)">
        <p>{{ question.question }}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path
            d="M480-349.56q-10.26 0-20.09-3.92-9.82-3.91-17.52-11.61L258.96-548.52Q244-563.48 244-585.57q0-22.08 14.96-37.04 14.95-14.96 37.04-14.96t37.04 14.96L480-475.65l146.96-146.96q14.95-14.96 37.04-14.96t37.04 14.96Q716-607.65 716-585.57q0 22.09-14.96 37.05L517.61-365.09q-7.7 7.7-17.52 11.61-9.83 3.92-20.09 3.92Z"
          />
        </svg>
      </div>
      <div class="content">
        <p>{{ question.answer }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const questionOpenIndex = ref(-1);
    const toggleQuestion = (index) => {
      if (questionOpenIndex.value === index) {
        questionOpenIndex.value = -1;
      } else {
        questionOpenIndex.value = index;
      }
    };

    const commonQuestions = [
      {
        question: "¿Qué es CARRY.?",
        answer:
          "Es un Software como Servicio (SaaS) que permite a las empresas y profesionales de servicios legales automatizar sus procesos con una amplia suite de funciones, en constante evolución para adaptarse a las necesidades de la industria.",
      },
      {
        question: "¿Necesito instalar algo?",
        answer:
          "No, es una plataforma en la nube, por lo que solo necesitas una conexión a internet y un navegador web para acceder y utilizarla.",
      },
      {
        question: "¿Necesito ser un experto en tecnología?",
        answer:
          "Nos enfocamos en crear un diseño intuitivo y amigable para que no necesites conocimientos técnicos avanzados, ¿qué esperas para probarla?",
      },
      {
        question:
          "¿Necesito un contrato a largo plazo o tarjeta para probarla?",
        answer:
          "Nada de eso, contrata y cancela cuando quieras sin plazos forzosos, además el plan gratuito te permite utilizarla sin necesidad de registrar una tarjeta.",
      },
      {
        question: "¿Mi información esta segura?",
        answer:
          "Absolutamente, utilizamos tecnología y proveedores de punta para proteger tus datos y los de tus clientes. Por ejemplo, tus documentos siempre están cifrados y solo tu puedes acceder a ellos. Actualmente esperamos a recibir la certificación ISO 27001 que garantiza la seguridad de la información.",
      },
    ];

    return {
      questionOpenIndex,
      toggleQuestion,
      commonQuestions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common_questions.scss";
</style>
