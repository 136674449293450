export default [
  {
    path: "/aviso-privacidad",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyPage.vue"),
  },
  {
    path: "/manifesto",
    name: "manifesto",
    component: () =>
      import(/* webpackChunkName: "manifesto" */ "../views/ManifestoPage.vue"),
  },
];
