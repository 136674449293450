<template>
  <nav class="navbar scrolled">
    <img src="./assets/logos/carry-grn-yllw.svg" alt="logo" @click="goHomePg" />
    <img src="./assets/logos/c-grn-yllw.svg" alt="logo" @click="goHomePg" />
    <router-link :to="{ name: 'home' }" class="main">Software</router-link>
    <router-link :to="{ name: 'login' }">Acceder</router-link>
    <router-link :to="{ name: 'signup' }" class="cta">Probar</router-link>
  </nav>
  <router-view />
  <loading-modal v-if="isLoading"></loading-modal>
</template>

<script>
import { useRouter } from "vue-router";
import { defineAsyncComponent, computed } from "vue";
import { useLoadingContentStore } from "@/stores/loadingContent";
import Intercom from "@intercom/messenger-js-sdk";

export default {
  components: {
    LoadingModal: defineAsyncComponent(() =>
      import("@/modals/LoadingModal.vue")
    ),
  },

  setup() {
    const router = useRouter();
    const loadingStore = useLoadingContentStore();
    const isLoading = computed(() => loadingStore.isLoading);

    const goHomePg = () => {
      router.push({ name: "home" });
    };

    Intercom({
      app_id: "ks2iwj5k",
    });

    // window.addEventListener("scroll", function () {
    //   let navbar = document.querySelector(".navbar");

    //   if (window.scrollY > 10) {
    //     navbar.classList.add("scrolled");
    //   } else {
    //     navbar.classList.remove("scrolled");
    //   }
    // });

    return { isLoading, goHomePg };
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";
</style>
