<template>
  <main class="page_container">
    <section class="upper_div_for_difumination">
      <div class="header_intro">
        <h1>
          <span>Superpoderes</span> para equipos legales de
          <span>alto rendimiento</span>
        </h1>
        <h2>
          CARRY. convierte operaciones lentas e ineficientes en algo asombroso
          con un sistema todo-en-1 + IA.
        </h2>
        <button @click="goToSignUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path
              d="M480-80q-117 0-198.5-81.5T200-360v-160h560v160q0 117-81.5 198.5T480-80ZM200-600q0-105 68-183.5T440-877v277H200Zm320 0v-277q104 15 172 93.5T760-600H520Z"
            />
          </svg>
          Pruébalo gratis
        </button>
      </div>
      <div class="functions_div">
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 1 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Firmas a velocidad luz</h4>
              <p>
                Firma cualquier documento electrónicamente con e.firma (SAT) o
                autógrafa en segundos + NOM-151 + IA.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/esigns.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 2 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Expedientes colaborativos</h4>
              <p>
                Crea expedientes integrales, elige qué y con quién compartir
                para mejorar la productividad.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/files.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 3 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Control financiero</h4>
              <p>
                Registra cada gasto e ingreso, envía solicitudes de cobro a tus
                clientes y emite facturas (CFDI's).
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/finances.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 4 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Tésis y jurisprudencia</h4>
              <p>
                Busca directamente en la SCJN, obtén resúmenes con IA y
                guárdalos en tu cuenta o expedientes.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/library.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 5 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Análisis legal con IA</h4>
              <p>
                Analiza documentos, obtén insights y recomendaciones, así como
                respuestas a tus preguntas.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/veridai.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 6 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Documentos 24/7</h4>
              <p>
                Tus documentos siempre disponibles. Accede y compártelos, cuando
                lo necesites.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/documents.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 7 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Gestión de tareas</h4>
              <p>
                Crea, asigna y establece fechas límite para convertir cualquier
                proyecto en un éxito, sea grande o pequeño.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/tasks.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 8 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Calendario</h4>
              <p>
                Programa tus actividades, comparte eventos y recibe
                notificaciones para no olvidar nada.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/events.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
        <div
          class="function"
          :class="{ function_superpower: currentSuperpower === 9 }"
        >
          <div class="inner">
            <div class="description_div">
              <h4>Mide tu rendimiento</h4>
              <p>
                Números que dan sentido a tu negocio. Visualiza reportes de tu
                día a día para decisiones informadas.
              </p>
            </div>
            <div class="multimedia_div">
              <img
                src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/statics.webp"
                alt="imagen"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section_div">
      <h3>Conecta las <span>apps</span> que amas</h3>
      <p>
        Mejora tu experiencia integrando aplicaciones que ya usas<sup>(1)</sup>
      </p>
      <div class="integrations_image_div">
        <img src="../assets/home_page_images/zoom.png" alt="zoom" />
        <img src="../assets/home_page_images/calendar.png" alt="calendar" />
        <img src="../assets/home_page_images/teams.png" alt="teams" />
        <img
          src="../assets/home_page_images/authenticator.png"
          alt="authenticator"
        />
        <img src="../assets/home_page_images/meet.png" alt="meet" />
        <img src="../assets/home_page_images/onedrive.png" alt="onedrive" />
        <img src="../assets/home_page_images/drive.png" alt="drive" />
      </div>
    </section>
    <section class="section_div section_top">
      <h3>
        Convierte la <span>IA</span> en <span>resultados</span> para tu negocio
      </h3>
      <p>
        Consigue mejores resultados con menos esfuerzo gracias a tu asistente
        IA.
      </p>
      <div class="ai_box_possibilities">
        <div class="input_box">
          <div class="cursor_line_simulation"></div>
          <div class="input_simulator">
            <p>Analicemos el documento</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
          >
            <path
              d="M176-183q-20 8-38-3.5T120-220v-180l320-80-320-80v-180q0-22 18-33.5t38-3.5l616 260q25 11 25 37t-25 37L176-183Z"
            />
          </svg>
        </div>
        <div class="possibilities">
          <div class="possibility">
            <p>¿Cuándo se firmó?</p>
          </div>
          <div class="possibility">
            <p>¿Cuál es el domicilio del proveedor?</p>
          </div>
          <div class="possibility">
            <p>¿Qué leyes se citan?</p>
          </div>
          <div class="possibility">
            <p>Crea un breve resumen</p>
          </div>
          <div class="possibility">
            <p>¿Cuál es la quantía?</p>
          </div>
          <div class="possibility">
            <p>¿Qué se demanda?</p>
          </div>
          <div class="possibility">
            <p>¿Por qué se aplazo?</p>
          </div>
          <div class="possibility">
            <p>¿Cómo fueron los hechos?</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section_div section_top">
      <h3>Mejora la <span>experiencia</span> de tus clientes</h3>
      <p>
        Involúcralos y manténlos informados en cada paso del camino, generando
        confianza y fidelidad.
      </p>
      <functionalities-clients></functionalities-clients>
    </section>
    <section class="section_div section_top">
      <h3>Casos de <span>éxito</span></h3>
      <p>Conoce como impactamos en diferentes áreas de las empresas</p>
      <trust-success></trust-success>
    </section>
    <section class="section_div section_top">
      <h3><span>Planes</span> disponibles</h3>
      <p>
        Escoge el mejor para tus necesidades. ¡Descuento por tiempo limitado!
      </p>
      <plans-pricing @startsignup="goToSignUp"></plans-pricing>
    </section>
    <section class="section_div section_top">
      <h3>Preguntas <span>comunes</span></h3>
      <common-questions></common-questions>
    </section>
    <footer>
      <section class="footer_logo">
        <img src="../assets/logos/c-grn-yllw.svg" alt="logo" />
        <img src="../assets/logos/carry-grn-yllw.svg" alt="logo" />
        <p>© 2025 AMP TECHNOLOGIES S.A.P.I. de C.V.</p>
        <p class="warning_parties">
          (1) La conexión a aplicaciones de terceros no esta disponible aún para
          todos los usuarios y regiones.
        </p>
        <p class="warning_parties">
          * Promoción válida para nuevos usuarios y hasta los primeros 1000
          usuarios en contratar el plan Pro o hasta el 31 de abril de 2025 (lo
          que ocurra primero).
        </p>
      </section>
      <div class="sections">
        <section>
          <h5>Enlaces</h5>
          <router-link :to="{ name: 'privacy' }"
            >Aviso de privacidad</router-link
          >
          <a
            href="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/TermsAndPrivacy/TERMINOS+Y+CONDICIONES+CARRY.pdf"
            target="_blank"
            >Términos y condiciones</a
          >
          <router-link :to="{ name: 'manifesto' }">Manifesto</router-link>
          <!-- <router-link :to="{ name: 'help' }">Ayuda</router-link> -->
        </section>
        <section>
          <h5>Social</h5>
          <a href="https://www.facebook.com/carry.legal" target="_blank"
            >Facebook</a
          >
          <a href="https://www.instagram.com/carry.legal/" target="_blank"
            >Instagram</a
          >
          <a href="https://www.linkedin.com/company/carrylegal" target="_blank"
            >LinkedIn</a
          >
          <a href="https://www.tiktok.com/@carry.legal" target="_blank"
            >TikTok</a
          >
        </section>
      </div>
    </footer>
  </main>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import FunctionalitiesClients from "@/modules/home/components/FunctionalitiesClients.vue";
import TrustSuccess from "@/modules/home/components/TrustSuccess.vue";
import PlansPricing from "@/modules/home/components/PlansPricing.vue";
import CommonQuestions from "@/modules/home/components/CommonQuestions.vue";

export default {
  name: "HomeView",
  components: {
    FunctionalitiesClients,
    TrustSuccess,
    PlansPricing,
    CommonQuestions,
  },

  setup() {
    const router = useRouter();
    const currentSuperpower = ref(1);
    const isMobile = ref(window.innerWidth <= 767);
    let observer = null;

    // Función para actualizar si es móvil
    const updateScreenSize = () => {
      isMobile.value = window.innerWidth <= 767;

      if (!isMobile.value) {
        currentSuperpower.value = null;
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      } else {
        currentSuperpower.value = 1; // Asegura que un superpoder esté activo en móvil
        observeFunctions();
      }
    };

    // Función para observar qué elemento está en pantalla
    const observeFunctions = () => {
      if (!isMobile.value || observer) return; // Prevenir múltiples instancias del observador

      const functionElements = document.querySelectorAll(".function");

      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = Array.from(functionElements).indexOf(entry.target);
              currentSuperpower.value = index + 1; // Asegura que sea un número válido
            }
          });
        },
        { threshold: 0.84 } // Se activará con solo el 84% visiblecarry
      );

      functionElements.forEach((el) => observer.observe(el));
    };

    // Inicializar cuando el componente está montado
    onMounted(() => {
      updateScreenSize(); // Se ejecuta primero para detectar la pantalla
      window.addEventListener("resize", updateScreenSize);

      if (isMobile.value) {
        observeFunctions();
      }
    });

    // Desmontar observador cuando el componente se destruye
    onUnmounted(() => {
      window.removeEventListener("resize", updateScreenSize);
      if (observer) observer.disconnect();
    });

    const goToSignUp = () => {
      router.push({ name: "signup" });
    };

    return {
      goToSignUp,
      currentSuperpower,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/home_page.scss";
</style>
