<template>
  <section class="successes_container">
    <div class="success">
      <div class="left">
        <img
          src="../../../assets/home_page_images/schmidt.webp"
          alt="cliente"
        />
      </div>
      <div class="right">
        <h5>Despachos legales - <span>Wobeser, Castro & Inclán</span></h5>
        <p>
          La evolución del sector legal depende de la digitalización. Con la
          herramienta adecuada como CARRY. pasamos a procesos más ágiles,
          eficientes y seguros.
        </p>
      </div>
    </div>
    <div class="success">
      <div class="left">
        <img
          src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/visas.webp"
          alt="cliente"
        />
      </div>
      <div class="right">
        <h5>Servicios consulares y auxiliares - <span>Visas 4 All</span></h5>
        <p>
          Pagaba 4 diferentes soluciones y ahora tener todo en una sola
          plataforma mejoró el trabajo con clientes y equipo.
        </p>
      </div>
    </div>
    <div class="success">
      <div class="left">
        <img
          src="https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/comercio.webp"
          alt="cliente"
        />
      </div>
      <div class="right">
        <h5>Comercio - <span>Comercializadora Gazprom</span></h5>
        <p>
          Firmar documentos con proveedores y empleados con una interfaz
          amigable, un asistente IA integrado que ayuda a entender lo que se
          firma y precios justos por si solo es un éxito.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/trust_success.scss";
</style>
