<template>
  <article class="functionalities_clients_box">
    <div class="left">
      <div
        class="option"
        :class="{ selected_option: selectedOption === 'files' }"
        @click="selectedOption = 'files'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path
            d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Z"
          />
        </svg>
        <p>Expedientes</p>
      </div>
      <div
        class="option"
        :class="{ selected_option: selectedOption === 'events' }"
        @click="selectedOption = 'events'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path
            d="m438-342 139-139q12-12 29-12t29 12q12 12 12 29t-12 29L466-254q-12 12-28 12t-28-12l-85-85q-12-12-12-29t12-29q12-12 29-12t29 12l55 55ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z"
          />
        </svg>
        <p>Eventos</p>
      </div>
      <div
        class="option"
        :class="{ selected_option: selectedOption === 'finance' }"
        @click="selectedOption = 'finance'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path
            d="M237-120q-23 0-44.5-16T164-175q-25-84-41-145.5t-25.5-108Q88-475 84-511t-4-69q0-92 64-156t156-64h200q27-36 68.5-58t91.5-22q25 0 42.5 17.5T720-820q0 6-1.5 12t-3.5 11q-4 11-7.5 22t-5.5 24l91 91h47q17 0 28.5 11.5T880-620v210q0 13-7.5 23T852-372l-85 28-50 167q-8 26-29 41.5T640-120h-80q-33 0-56.5-23.5T480-200h-80q0 33-23.5 56.5T320-120h-83Zm403-400q17 0 28.5-11.5T680-560q0-17-11.5-28.5T640-600q-17 0-28.5 11.5T600-560q0 17 11.5 28.5T640-520Zm-160-80q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680H360q-17 0-28.5 11.5T320-640q0 17 11.5 28.5T360-600h120Z"
          />
        </svg>
        <p>Cobros</p>
      </div>
      <div
        class="option"
        :class="{ selected_option: selectedOption === 'esigns' }"
        @click="selectedOption = 'esigns'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path
            d="M611-461 461-612l111-110-29-29-191 191q-12 12-28 12t-28-12q-12-12-12-28.5t12-28.5l190-190q24-24 56.5-24t56.5 24l29 29 50-50q12-12 28.5-12t28.5 12l93 93q12 12 12 28.5T828-678L611-461ZM160-120q-17 0-28.5-11.5T120-160v-77q0-16 6-30.5t17-25.5l261-262 151 150-262 262q-11 11-25.5 17t-30.5 6h-77Z"
          />
        </svg>
        <p>Firmas</p>
      </div>
      <div
        class="option"
        :class="{ selected_option: selectedOption === 'documents' }"
        @click="selectedOption = 'documents'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path
            d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h287q16 0 30.5 6t25.5 17l194 194q11 11 17 25.5t6 30.5v447q0 33-23.5 56.5T720-80H240Zm280-560q0 17 11.5 28.5T560-600h160L520-800v160Z"
          />
        </svg>
        <p>Documentos</p>
      </div>
    </div>
    <div class="right up-and-down">
      <transition name="fade-slide">
        <img
          :key="selectedOption"
          :src="urlImageClientFunctionalities"
          alt="ilustración"
        />
      </transition>
    </div>
  </article>
</template>

<script>
import { ref, computed } from "vue";

export default {
  setup() {
    const selectedOption = ref("files");

    const urlImageClientFunctionalities = computed(() => {
      switch (selectedOption.value) {
        case "events":
          return "https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/eventsillustration.webp";
        case "finance":
          return "https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/paymentsillustration.webp";
        case "documents":
          return "https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/documentsillustration.webp";
        case "esigns":
          return "https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/esignsillustration.webp";
        case "files":
        default:
          return "https://public-resources-for-emails.s3.us-east-1.amazonaws.com/Web-Page-Resources/filesillustration.webp";
      }
    });

    return {
      urlImageClientFunctionalities,
      selectedOption,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/functionalities_clients.scss";
</style>
