import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import loginSignupRoutes from "@/modules/authentication/router/loginSignupRoutes";
import eventRoutes from "@/modules/events/routes/eventRoutes";
import documentsRoutes from "@/modules/documents/routes/documentsRoutes";
import paymentsRoutes from "@/modules/payments/routes/paymentsRoutes";
import esignsRoutes from "@/modules/esigns/routes/esignsRoutes";
import helpRoutes from "@/modules/help/routes/helpRoutes";
import othersRoutes from "@/modules/others/routes/othersRoutes";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  ...loginSignupRoutes,
  ...eventRoutes,
  ...documentsRoutes,
  ...paymentsRoutes,
  ...esignsRoutes,
  ...helpRoutes,
  ...othersRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,

  scrollBehavior(to, from, savedPosition) {
    // Si hay una posición guardada (por ejemplo, al usar el botón de retroceso), restaurarla
    if (savedPosition) {
      return savedPosition;
    } else {
      // De lo contrario, mover la vista hasta arriba
      return { top: 0, left: 0, behavior: "smooth" };
    }
  },
});

export default router;
