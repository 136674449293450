<template>
  <section class="plans_pricing_container">
    <div class="pricing">
      <div class="head">
        <div class="icon_name">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40px"
            viewBox="0 -960 960 960"
            width="40px"
            fill="#f4bb2c"
          >
            <path
              d="M853.33-315.33V-562l-342 185q-15.33 8.67-32 8.33-16.66-.33-32-9L93.33-571q-9-5.33-12.83-12.68t-3.83-16.34q0-8.98 3.83-16.31 3.83-7.34 12.83-12.67l354-193.33q7.58-4.34 15.58-6.5 8-2.17 16.42-2.17 8.42 0 16.42 2.17 8 2.16 15.58 6.5l391 212.66q8.42 4.24 13.04 12.29Q920-589.32 920-580v264.67q0 14.16-9.62 23.75-9.61 9.58-23.83 9.58-14.22 0-23.72-9.58-9.5-9.59-9.5-23.75Zm-406 177.66-220-120q-16-9-25.33-24.83-9.33-15.83-9.33-34.17v-166.66l254.66 139q15.11 8.66 31.89 8.66t32.11-8.66l254.67-139v166.66q0 18.34-9.33 34.17-9.34 15.83-25.34 24.83l-220 120q-7.58 4.34-15.58 6.5-8 2.17-16.42 2.17-8.42 0-16.42-2.17-8-2.16-15.58-6.5Z"
            />
          </svg>
          <h5>Gratuito</h5>
        </div>
        <h6>0 MXN/mes</h6>
        <p>El sueño apenas comienza</p>
      </div>
      <div class="features">
        <ul>
          <li
            v-for="(benefit, indexBenefit) in freeBenefits"
            :key="indexBenefit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#e8eaed"
            >
              <path
                d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"
              />
            </svg>
            <p>{{ benefit }}</p>
          </li>
        </ul>
        <p class="note">
          *Las cantidades se recargan al mes a excepción del almacenamiento y
          expedientes.
        </p>
      </div>
    </div>
    <div class="pricing pro">
      <div class="head">
        <div class="icon_name">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40px"
            viewBox="0 -960 960 960"
            width="40px"
            fill="#f4bb2c"
          >
            <path
              d="m437.33-439.33-68-69.67q-9.66-10.33-23.83-10.33-14.17 0-24.83 10-10.34 10.33-10.34 24.66 0 14.34 10.34 24.67L414-367.33q10 10 23.33 10 13.34 0 23.34-10l178.66-178q10-10 9.84-24-.17-14-10.5-24-10-9.34-23.84-9.17-13.83.17-23.16 9.5L437.33-439.33ZM330-86l-60.67-103.33L150-214q-12.33-2.33-20.33-13.17-8-10.83-6-23.16l13-117.67L59-458q-8.67-9-8.67-22T59-502l77.67-89.33-13-117.67q-2-12.33 6-23.17 8-10.83 20.33-13.16L269.33-770 330-874q6.67-11 18.67-15.17 12-4.16 24 1.17L480-839.33 587.33-888q12-5.33 24-1.5T630-874.67L691.33-770 810-745.33q12.33 2.33 20.33 13.16 8 10.84 6 23.17l-13 117.67L901-502q8.67 9 8.67 22T901-458l-77.67 90 13 117.67q2 12.33-6 23.16-8 10.84-20.33 13.17l-118.67 24.67-61.33 104q-6.67 11-18.67 14.83-12 3.83-24-1.5L480-120.67 372.67-72q-12 5.33-24 1.17Q336.67-75 330-86Z"
            />
          </svg>
          <h5>Pro</h5>
        </div>
        <h6 class="canceled_price">349 MXN</h6>
        <h6>299 MXN/mes</h6>
        <p>El sueño es una realidad</p>
      </div>
      <div class="features">
        <ul>
          <li
            v-for="(benefit, indexProBenefit) in proBenefits"
            :key="indexProBenefit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#e8eaed"
            >
              <path
                d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"
              />
            </svg>
            <p>{{ benefit }}</p>
          </li>
        </ul>
        <p class="note">
          *Las cantidades se recargan al mes a excepción del almacenamiento y
          expedientes.
        </p>
      </div>
    </div>
  </section>
  <section class="guarantee_container">
    <div class="guarantee">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#e8eaed"
      >
        <path
          d="M192-492 92-592q-12-12-12-28t12-28l100-100q12-12 28-12t28 12l100 100q12 12 12 28t-12 28L248-492q-12 12-28 12t-28-12ZM400-80q-17 0-28.5-11.5T360-120v-160q-50-4-99-11t-98-18q-17-4-27.5-19t-5.5-32q5-17 20.5-25t32.5-4q73 17 147.5 23t149.5 6q75 0 149.5-6T777-389q17-4 32.5 4t20.5 25q5 17-5.5 32T797-309q-49 11-98 18t-99 11v160q0 17-11.5 28.5T560-80H400Zm80-600q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0 280q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm191-100-46-80q-5-9-5-20t5-20l46-80q5-10 14-15t20-5h90q11 0 20 5t14 15l46 80q5 9 5 20t-5 20l-46 80q-5 10-14 15t-20 5h-90q-11 0-20-5t-14-15Z"
        />
      </svg>
      <p>Cancela cuando quieras</p>
    </div>
    <div class="guarantee">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#e8eaed"
      >
        <path
          d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM364-182l48-110q-42-15-72.5-46.5T292-412l-110 46q23 64 71 112t111 72Zm-72-366q17-42 47.5-73.5T412-668l-46-110q-64 24-112 72t-72 112l110 46Zm188 188q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm116 178q63-24 110.5-71.5T778-364l-110-48q-15 42-46 72.5T550-292l46 110Zm72-368 110-46q-24-63-71.5-110.5T596-778l-46 112q41 15 71 45.5t47 70.5Z"
        />
      </svg>
      <p>Soporte disponible</p>
    </div>
    <div class="guarantee">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#e8eaed"
      >
        <path
          d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm240-200q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80Z"
        />
      </svg>
      <p>Información cifrada</p>
    </div>
  </section>
  <section class="cta_div">
    <p>
      Optimiza operaciones, fomenta la innovación y gana sobre competidores
      escépticos.
    </p>
    <button @click="startsignup">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#e8eaed"
      >
        <path
          d="M480-80q-117 0-198.5-81.5T200-360v-160h560v160q0 117-81.5 198.5T480-80ZM200-600q0-105 68-183.5T440-877v277H200Zm320 0v-277q104 15 172 93.5T760-600H520Z"
        />
      </svg>
      Probar ahora
    </button>
  </section>
</template>

<script>
export default {
  emits: ["startsignup"],

  setup(_, { emit }) {
    const startsignup = () => {
      emit("startsignup");
    };

    const freeBenefits = [
      "5 expedientes",
      "2 GB de almacenamiento",
      "1 firma electrónica",
      "1 validación de identidad (KYC)",
      "20 búsquedas de jurisprudencia",
      "20 creación de eventos",
      "10 creación de cobros",
      "40 creación de tareas",
    ];

    const proBenefits = [
      "Expedientes ilimitados",
      "100 GB de almacenamiento",
      "5 Firmas electrónicas",
      "5 validaciones de identidad (KYC)",
      "IA para analizar hasta 3000 páginas",
      "Búsquedas ilimitadas de jurisprudencia",
      "Creación ilimitada de eventos",
      "Creación ilimitada de cobros",
      "Creación ilimitada de tareas",
      "10 timbres para CFDI's",
      "Calculadoras legales",
    ];

    return {
      freeBenefits,
      proBenefits,
      startsignup,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/plans_pricing.scss";
</style>
